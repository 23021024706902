<template>
  <el-row :gutter="24">
    <el-col :span="10">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-button type="primary" @click="addRole">
            <el-icon style="vertical-align: middle">
              <Plus />
            </el-icon>
            <span style="vertical-align: middle"> 新增角色 </span>
          </el-button>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div>
        <el-tag size="Large" round="true" type="success">角色管理</el-tag>
        <el-table :data="tableData" stripe style="width: 100%" highlight-current-row
          @current-change="handleCurrentChange">
          <el-table-column prop="roleName" label="角色名称" />
          <el-table-column prop="createTime" label="创建时间" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button size="small" type="danger" v-hasPer="['roles:del']" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-col>
    <el-col :span="14">
      <el-row :gutter="24">
        <el-col :span="2" >
             <el-tag size="large" type="success">权限分配</el-tag>
        </el-col>
        <el-col :span="12"  v-show="btnVisible">
          <el-button type="primary" @click="submit"> 保存</el-button>
          <el-button type="warning" @click="cancle"> 取消</el-button>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div>
        <el-tree ref="tree" :data="treeData" show-checkbox node-key="id" :default-expand-all="false" @check="checKey"
          :default-expanded-keys="expandedkeys" :default-checked-keys="checkedkeys" :props="defaultProps" />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      small: false,
      background: true,
      tableData: [],
      defaultProps: {
        children: 'children',
        label: 'text'
      },
      treeData: [],
      expandedkeys: [],//默认展开的ID,
      checkedkeys: [],//默认选中ID
      btnVisible: false,
      currentItem: null
    }
  },
  created() {
    this.init();
  },
  methods: {
    //权限分配提交
    submit() {
      const _this = this;
      let pid = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
      if (pid.length > 0) {
        this.currentItem.pid = pid.join(",");
      } else {
        ElMessage('请选择要分配的角色')
        return false;
      }
      this.$https.post('api/admin/roles/InsertOrUpdate', this.currentItem).then(res => {
        if (res.success) {
          ElMessage({
            type: 'success',
            message: res.msg
          })
          _this.init();
        }
      });
    },
    addRole() {
      const _this = this;
      ElMessageBox.prompt('请输入角色名称', '提示', {
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        inputPattern: !/^s*$/,
        inputErrorMessage: '角色名不能为空',
      }).then(({ value }) => {
        this.$https.post('api/admin/roles/InsertOrUpdate', {
          rolename: value
        }).then(res => {
          if (res.success) {
            ElMessage({
              type: 'success',
              message: res.msg
            })
            _this.init();
          }
        });
      })
    },
    handleDelete(index, item) {
      const _this = this;
      this.$https.get('api/admin/roles/del', { params: { id: item.id } }).then(res => {
        if (res.success) {
          ElMessage({
            type: 'success',
            message: res.msg
          })
          _this.init();
        }
      })
    },
    init() {
      let _this=this;
      this.$https.get('api/admin/roles/getlist', {
        params: {
          page: this.currentPage,
          limit: this.pageSize
        }
      }).then(res => {
        if (res.success) {
          var result=res.data;
          _this.tableData = result.data;
        }
      })

      _this.initree();
    },
    initree() {
      const _this = this;
      //获取树节点
      this.$https.get('api/admin/Permission/GetPertreeModel').then(res => {
        if (res.success) {
          _this.treeData = res.data;
          if(_this.currentItem!=null){
            console.log(_this.currentItem)
            _this.handleCurrentChange(this.currentItem);
          }
        }
      })
    },
    handleEdit(index, row) {
      this.dialogVisible = true;
    },
    handleSizeChange() {

    },
    //点击复选框事件
    checKey() {
      if (this.currentItem == null) {
        ElMessage('请选择左侧角色再勾选权限')
        return false;
      }
      this.btnVisible = true;
    },
    //点击角色行事件
    handleCurrentChange(item) {
      if(item==null)
        return  false;
      this.currentItem = item;
      if (item.pid == null || item.pid == '') {
        this.$refs.tree.setCheckedKeys([]);
        return false;
      }
      const pid = this.treeData.filter(item => item.parentId == '0').map(citem=>citem.id);
      this.btnVisible = false;
      this.checkedkeys = item.pid.split(",").filter(value=>!pid.includes(value));
      this.$refs.tree.setCheckedKeys(this.checkedkeys);
    },
    cancle() {
      this.btnVisible = false;
      this.$refs.tree.setCheckedKeys(this.checkedkeys);
    }
  }
}
</script>

<style>
.pagin {
  margin-top: 10px;
}
</style>